<template>
     <v-row no-gutters justify="center" align="center"  class="mt-5 pt-5" >
          <v-col cols="12" >
            <v-divider :class="(light) ? 'light' : 'dark'" ></v-divider>
              <v-breadcrumbs :items="breadItems">
                  <template v-slot:divider>
                    <v-icon :color="(light) ? 'light' : 'dark'" >mdi-chevron-right</v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                      :href="item.href"
                      :disabled="item.disabled"
                      :class="(light) ? 'light--text' : 'dark--text'"
                    >
                      <span :class="(light) ? 'light--text' : 'dark--text'" v-if="!item.isparam">{{ $t('router.'+item.text+'.title') }}</span>
                      <span :class="(light) ? 'light--text' : 'dark--text'" v-else>{{ item.text }}</span> 
                    </v-breadcrumbs-item>
                  </template>
              </v-breadcrumbs>
            <v-divider :class="(light) ? 'light' : 'dark'" ></v-divider>
          </v-col>  
    </v-row>
</template>
<script>
import {mapGetters} from "vuex";
export default {
    props:{
       light:{
            type: Boolean,
            required: false,
       },
    },
    computed: {
      ...mapGetters({
            loading: 'loading/loading',
        }),
        breadItems() {
               var breadItems = [];
               breadItems = breadItems.concat(this.$route.meta.breadCrumbs)
               console.log('breadItems',breadItems)
               return breadItems
        },
    },
    methods:{
      refresh(){
        location.reload()
      }
    }
}
</script>